import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import banner1 from '../../img/banners/5.jfif'
import banner2 from '../../img/slider/2.jfif'
import banner3 from '../../img/1.jfif'
import banner4 from '../../img/banner4.jfif'
// import slider1 from '../../img/slider/1.jfif'
// import slider2 from '../../img/slider/2.jfif'
 import slider3 from '../../img/slider/1.jfif'
 import slider4 from '../../img/slider/9.PNG'
import slider1 from '../../img/slider/7-1.jpg'
import slider2 from '../../img/slider/8.jfif'
import slider5 from '../../img/slider/10.jpg'
//import slider3 from '../../img/banners/8.jfif'
//import slider4 from '../../img/banners/9.jfif'
// import slider5 from '../../img/slider/6.jfif'
import restauratns from '../../img/partners/restauratns.jpg'
import logistics from '../../img/partners/logistics.jpg'
import onlineTrading from '../../img/partners/onlineTrading.jpg'
import tourism from '../../img/partners/tourism.jpg'
import support from '../../img/partners/support.jpg'
import flight from '../../img/partners/flight.jpg'
import adcan from '../../img/customers/adcan.png'
import global_pharma from '../../img/customers/global_pharma.png'
import life from '../../img/customers/life.jpg'
import { Carousel } from 'react-bootstrap'
import '../../css/Custom.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlay } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import flight1 from '../../img/partners/flight_01.jpg'
import logistics1 from '../../img/partners/logistics_01.jpg'
import onlineTrading1 from '../../img/partners/onlineTrading_01.jpg'
import restauratns1 from '../../img/partners/restauratns_01.jpg'
import support1 from '../../img/partners/support_01.jpg'
import tourism1 from '../../img/partners/tourism_01.jpg'
import partner01 from '../../img/partners/01-partner.jpg'
import partner02 from '../../img/partners/02-partner.jpg'
import partner03 from '../../img/partners/03-partner.jpg'
import partner04 from '../../img/partners/04-partner.jpg'
import partner05 from '../../img/partners/05-partner.jpg'
import customer01 from '../../img/customers/01-customer.jpg'
import customer02 from '../../img/customers/02-customer.jpg'
import customer03 from '../../img/customers/03-customer.jpg'
import customer04 from '../../img/customers/04-customer.jpg'
import customer05 from '../../img/customers/05-customer.jpg'

const more = <FontAwesomeIcon icon={faPlay} style={{fontSize:'10px'}} />
export default function Home() {
  function FadeInSection2(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section2 ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }
  return (
    <>
    <Helmet>
        <title>MenaPac| Home</title>
      </Helmet>
    <Carousel className="slidermain">
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider"   
        src={slider3}
        alt="First slide"
       
        />
        <Carousel.Caption className={"cap"}>
        <h3>We are a Manufacturer, Importer, Distributor of Pharmaceutical Packaging Products.</h3>
        {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider"
        src={slider1}
        alt="Second slide"
       
        />
        <Carousel.Caption>
        <h3>Innovative Vials Amber & Flint Bottles Solutions for Pharma.</h3>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider"
        src={slider2}
        alt="Second slide"
      
        />
        <Carousel.Caption>
        <h3>Over 150+ Satisfied Customers in MENA & Africa.</h3>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider" 
        src={slider4}
        alt="Second slide"
    
        />
        <Carousel.Caption>
        <h3>Hundreds of PVC products from ETO, Steam, Gamma rays, Beta rays…</h3>
       
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
    </Carousel.Item>
 
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider"
        src={slider5}
        alt="Second slide"
       
        />
        <Carousel.Caption>
        <h3>Fully Certified Class 100,000 Clean Room Facility Glass Bottles.</h3>
      
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
    </Carousel.Item>
   
</Carousel>

<div className="container help mt-5 maincontent" style={{marginBottom:'3%'}}>
{/* <FadeInSection2 key='1' >  */}
<div className='row' >
 
  
<div className="col-md-6 greybg" >
      <h2 style={{paddingTop: "4%"}}>WHO WE ARE ?</h2>
      <p style={{marginTop:'6%',textAlign:'left'}}>MENAPAC is a leading distributor of pharmaceutical packaging and equipments. We have been providing top-quality products, custom-tailored medical packaging solutions, and outstanding service to customers worldwide. At MENAPAC, we are proud to meet the needs of medication packagers in a variety of market segments, from independent pharmacies to long-term care facilities, hospitals, and many others.</p>
      <Link to="/about-us" style={{color:'#4bb4f7',textAlign:'left',textDecoration: 'none'}}>Read More &nbsp; {more}</Link>
    </div>
    <div className="col-md-6" style={{paddingLeft:'0px',paddingRight:'0px'}}>
    {/* <div className="video-responsive">
    <iframe width="460" height="315" src="https://www.youtube.com/embed/lwQ4bDteBFw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
   </div> */}
   <img src={banner1}  style={{width:'100%'}}/>

    </div>

</div>
{/* </FadeInSection2> */}


</div>
<div className="container">
  <h2 style={{textAlign:'center'}}>OUR PARTNERS</h2>
  <div className='row'>
      <div className="graNnH">

        <div className="jsx-3387020902 eapp-logo-showcase-logo-component">
          <div className="jsx-3387020902 eapp-logo-showcase-logo-inner">
            <div className="jsx-3387020902 eapp-logo-showcase-logo-innerInner">
              <a href="#">
                <img src={partner01} alt="" tabindex="0" class="dPOiux" />
              </a>
            </div>            
          </div>        
        </div>
        
        <div className="jsx-3387020902 eapp-logo-showcase-logo-component">
          <div className="jsx-3387020902 eapp-logo-showcase-logo-inner">
            <div className="jsx-3387020902 eapp-logo-showcase-logo-innerInner">
              <a href="#">
                <img src={partner02} alt="" tabindex="0" class="dPOiux" />
              </a>
            </div>            
          </div>        
        </div>
        
        <div className="jsx-3387020902 eapp-logo-showcase-logo-component">
          <div className="jsx-3387020902 eapp-logo-showcase-logo-inner">
            <div className="jsx-3387020902 eapp-logo-showcase-logo-innerInner">
              <a href="#">
                <img src={partner03} alt="" tabindex="0" class="dPOiux" />
              </a>
            </div>            
          </div>        
        </div>
        
        <div className="jsx-3387020902 eapp-logo-showcase-logo-component">
          <div className="jsx-3387020902 eapp-logo-showcase-logo-inner">
            <div className="jsx-3387020902 eapp-logo-showcase-logo-innerInner">
              <a href="#">
                <img src={partner04} alt="" tabindex="0" class="dPOiux" />
              </a>
            </div>            
          </div>        
        </div>
        
        <div className="jsx-3387020902 eapp-logo-showcase-logo-component">
          <div className="jsx-3387020902 eapp-logo-showcase-logo-inner">
            <div className="jsx-3387020902 eapp-logo-showcase-logo-innerInner">
              <a href="#">
                <img src={partner05} alt="" tabindex="0" class="dPOiux" />
              </a>
            </div>            
          </div>        
        </div>

      </div>
  </div>
</div>



<div className="container maincontent partners">
  <h2>OUR SISTERS COMPANY</h2>
  <div className="row align-center justify-content-center">
    <div className="p-3 col-12 col-md-6 col-lg-4">
      <div className="client-wrapper">
        <div className="wrap-img">
          <img src={flight1} className="img-responsive clients-img" />
        </div>
        <div className="card-box">
          <a href="">
            Flight Support
          </a>                     
        </div> 
      </div>
    </div>

    <div className="p-3 col-12 col-md-6 col-lg-4">
      <div className="client-wrapper">
        <div className="wrap-img">
          <img src={logistics1} className="img-responsive clients-img" />
        </div>
        <div className="card-box">
          <a href="">
            Shipping & Logistics
          </a>           
        </div> 
      </div>
    </div>
    
    <div className="p-3 col-12 col-md-6 col-lg-4">
      <div className="client-wrapper">
        <div className="wrap-img">
          <img src={onlineTrading1} className="img-responsive clients-img" />
        </div>
        <div className="card-box">
          <a href="">
            Online Trading
          </a>          
        </div> 
      </div>
    </div>

    <div className="p-3 col-12 col-md-6 col-lg-4">
      <div className="client-wrapper">
        <div className="wrap-img">
          <img src={restauratns1} className="img-responsive clients-img" />
        </div>
        <div className="card-box">
          <a href="">
            Restaurants
          </a>          
        </div> 
      </div>
    </div>

    <div className="p-3 col-12 col-md-6 col-lg-4">
      <div className="client-wrapper">
        <div className="wrap-img">
          <img src={support1} className="img-responsive clients-img" />
        </div>
        <div className="card-box">
          <a href="">
            Support & Maintenance Trading
          </a>           
        </div> 
      </div>
    </div>
    
    <div className="p-3 col-12 col-md-6 col-lg-4">
      <div className="client-wrapper">
        <div className="wrap-img">
          <img src={tourism1} className="img-responsive clients-img" />
        </div>
        <div className="card-box">
          <a href="">
            Travel & Tourism
          </a>            
        </div> 
      </div>
    </div>

  </div>
</div>



<div className="container help mt-5 maincontent" style={{display:'none'}}>

<div className='row'>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${restauratns})`  }}> 
<p className="partnerhead">Restaurants</p>
</div>

<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${logistics})` }}>
  <a href="https://timexpress.ae" target="_blank" style={{textDecoration:'none'}}>
<p className="partnerhead">Shipping & Logistics</p> </a>
</div>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${onlineTrading})`}}>
<p className="partnerhead">Online Trading</p> 
</div>
</div>
<div className='row'>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${support})`  }}>
<p className="partnerhead">Support & Maintenance Trading</p> 
     </div>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${tourism})` }}>
<p className="partnerhead">Travel & Tourism</p> 
</div>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${flight})`}}>
<p className="partnerhead">Flight Support</p> 
</div>
</div>

</div>


  <div className="container-fluid homebg2 testimonial desk" style={{background:`url(${banner4})`}}>
<h2 style={{textAlign:'center'}}>OUR CUSTOMERS</h2>
   
<Carousel>
    <Carousel.Item>
    
    <div className="customer_slider">
     <div className="row" style={{paddingBottom:'3%'}}>
      <div className="col-md-3" style={{marginTop:'3px'}}>
        <img src={adcan} style={{width:'80%'}}/>
      </div>
      <div className="col-md-3">
        <img src={global_pharma} style={{width:'80%'}}/>
      </div>
      <div className="col-md-3" style={{marginTop:'3px'}}>
        <img src={life} style={{width:'80%'}}/>
      </div>
      <div className="col-md-3" style={{marginTop:'3px'}}>
        <img src={customer01} style={{width:'80%'}}/>
      </div>
     </div>
     
      </div>
    </Carousel.Item>
    <Carousel.Item>
    
    <div className="customer_slider">
     <div className="row" style={{paddingBottom:'3%'}}>
      <div className="col-md-3" style={{marginTop:'3px'}}>
        <img src={customer02} style={{width:'80%'}}/>
      </div>
      <div className="col-md-3" style={{marginTop:'3px'}}>
        <img src={customer03} style={{width:'80%'}}/>
      </div>
      <div className="col-md-3" style={{marginTop:'3px'}}>
        <img src={customer04} style={{width:'80%'}}/>
      </div>
      <div className="col-md-3" style={{marginTop:'3px'}}>
        <img src={customer05} style={{width:'80%'}}/>
      </div>
     </div>
     
      </div>
    </Carousel.Item>
    
    </Carousel>
</div>

<div className="container-fluid homebg2 testimonial mobile" style={{background:`url(${banner4})`}}>
<h2 style={{textAlign:'center'}}>OUR CUSTOMERS</h2>
   
<Carousel>
    <Carousel.Item>    
    <div className="customer_slider">
      <div className="row" style={{paddingBottom:'3%'}}>
        <div className="col-md-12" style={{marginTop:'3px'}}>
          <img src={adcan} style={{width:'100%'}}/>
        </div>
      </div>
    </div>
    </Carousel.Item>

    <Carousel.Item>    
      <div className="customer_slider">
        <div className="row" style={{paddingBottom:'3%'}}>
          <div className="col-md-12" style={{marginTop:'3px'}}>
            <img src={global_pharma} style={{width:'100%'}}/>
          </div>
        </div>
      </div>
    </Carousel.Item>

    <Carousel.Item>    
    <div className="customer_slider">
      <div className="row" style={{paddingBottom:'3%'}}>
        <div className="col-md-4" style={{marginTop:'3px'}}>
          <img src={life} style={{width:'100%'}}/>
        </div>
      </div>
    </div>
    </Carousel.Item>

    <Carousel.Item>    
    <div className="customer_slider">
      <div className="row" style={{paddingBottom:'3%'}}>
        <div className="col-md-4" style={{marginTop:'3px'}}>
          <img src={customer01} style={{width:'100%'}}/>
        </div>
      </div>
    </div>
    </Carousel.Item>

    <Carousel.Item>    
    <div className="customer_slider">
      <div className="row" style={{paddingBottom:'3%'}}>
        <div className="col-md-4" style={{marginTop:'3px'}}>
          <img src={customer02} style={{width:'100%'}}/>
        </div>
      </div>
    </div>
    </Carousel.Item>

    <Carousel.Item>    
    <div className="customer_slider">
      <div className="row" style={{paddingBottom:'3%'}}>
        <div className="col-md-4" style={{marginTop:'3px'}}>
          <img src={customer03} style={{width:'100%'}}/>
        </div>
      </div>
    </div>
    </Carousel.Item>

    <Carousel.Item>    
    <div className="customer_slider">
      <div className="row" style={{paddingBottom:'3%'}}>
        <div className="col-md-4" style={{marginTop:'3px'}}>
          <img src={customer04} style={{width:'100%'}}/>
        </div>
      </div>
    </div>
    </Carousel.Item>

    <Carousel.Item>    
    <div className="customer_slider">
      <div className="row" style={{paddingBottom:'3%'}}>
        <div className="col-md-4" style={{marginTop:'3px'}}>
          <img src={customer05} style={{width:'100%'}}/>
        </div>
      </div>
    </div>
    </Carousel.Item>
    
    </Carousel>
</div>
</>

  )
}
